var pawLibrary = {
	map: false,
	fbAccessToken: "1046599778716357|Ru5dCXFhXsqfy2-ziNbyZEGmOhs",
	monthNames: ["Jan","Feb","Mar","Apr","May","June","Jul","Aug","Sept","Oct","Nov","Dec"],
	fullMonthNames: ["January","February","March","April","May","June","July","August","September","October","November","December"],
	loading: "<div id=\"loader\" class=\"center\" style=\"width:100%\"><img src=\"./imgs/loader.gif\" alt=\"Loading...\"  /></div>",
	markers: [],
	dropdownMenus: function()
	{
		$('.parent > a').on('click', function(e) {
			var selected = $(this);
			var id = selected.attr('id');
			if(id != '') id = id.replace('showMenu','');

			if($('#subMenu' + id).length == 1)
			{
				e.preventDefault();
			}

			// Show
			if(selected.hasClass('highlight') == true) {
				// Hide
				selected.removeClass('highlight');
				$('.parent-sub').hide();
			} else {
				// Show
				selected.addClass('highlight');
				$('.parent-sub').hide();
				$('.parent a').removeClass('highlight');
				$('#subMenu' + id).fadeIn();
				$('#showMenu' + id).addClass('highlight');
			}
		});
	},
	search: function(term)
	{
		$('#searchresults-wrap').html('<div id="loading">Searching...</div>').load('ajax/search.inc.php?term=' + term);
	},
	validateForm: function()
	{
		var errors = 0;

		var name_txt = $('#name_txt');
		var email_txt = $('#email_txt');
		var message_txt = $('#message_txt');
		var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

		if(name_txt.val().length < 3)
		{
			name_txt.addClass('required_border');
			errors++;
		} else {
			name_txt.removeClass('required_border');
		}

		if( !filter.test(email_txt.val()) )
		{
			email_txt.addClass('required_border');
			errors++;
		} else {
			email_txt.removeClass('required_border');
		}

		if(message_txt.val().length < 3)
		{
			message_txt.addClass('required_border');
			errors++;
		} else {
			message_txt.removeClass('required_border');
		}

		return (errors == 0) ? true : false;

	},

	getTwitterProfilePic: function(elemId, username)
	{

		$.ajax({
			type: 'json',
		    url: '../ajax/getTwitter.inc.php?username=' + username,
		    beforeSend: function() {
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="./imgs/loader.gif" alt="'+username+'" class="loader" />');
		    },
		    success: function(data){
		    	var img = data[0].image;
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="'+img+'" alt="'+username+'" class="profile" />');
		    }
		});

	},

	generateMap: function(elem,lat,lng, zoomLevel) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: 4,
		  center: myLatlng,
      scrollwheel: false,
			draggable: true,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		}

		this.map = new google.maps.Map(document.getElementById(elem), myOptions);
	},

	addMarker: function(lat, lng, location_title, location_add) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var contentString = '<div id="mapContent" class="infoMarker">'+
			'<p><strong>'+location_title+'</strong></p>'+
				'<p>'+location_add+'</p>'+
				'<a class="btn" href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: this.map,
			title: location_title
		});

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(this.map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});
	},
	centerMap: function() {
		var center = this.map.getCenter();
		this.map.setCenter();
	},

	setMap : function(elem, lat, lng, location_title, location_add, drag) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: 14,
		  center: myLatlng,
      scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: drag
		}

		var map = new google.maps.Map(document.getElementById(elem), myOptions);

		var contentString = '<div id="mapContent">'+
			'<p><strong>'+location_title+'</strong></p>'+
				'<p>'+location_add+'</p>'+
				'<a class="btn" href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: map,
			title: location_title,
			draggable: drag
		});

		if(drag == true) {
			google.maps.event.addListener(marker, 'dragend', function() {
					var point = marker.position;
					if(lat_txt) lat_txt.value = point.$a;
					if(long_txt) long_txt.value = point.ab;

				});
		}

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});

	},

	setMapByGeocode : function(elemId, address, zoomlevel) {

		var mapOptions = {
		  zoom: zoomlevel,
      scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: false
		}

		var contentString = '<div id="mapContent">'+
				'<p>'+address+'</p>'+
				'<a href="http://maps.google.co.uk/maps?daddr='+encodeURI(address)+'">Get Directions</a> &raquo;' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
            content: contentString
         });

		var geocoder = new google.maps.Geocoder();
		var map = new google.maps.Map(document.getElementById(elemId), mapOptions);

		geocoder.geocode( {
			'address': address
				}, function(results, status) {
					  if (status == google.maps.GeocoderStatus.OK) {
						map.setCenter(results[0].geometry.location);
						var marker = new google.maps.Marker({
							map: map,
							position: results[0].geometry.location
						});

						google.maps.event.addListener(marker, 'click', function() {
						  infowindow.open(map,marker);
						});

					  }
					}
		);

	},

	mobileNav: function()
	{
		var mobileNavItems = $('#mobile-nav-items');
		var mobileBtn = $('#mobile-burger-btn');
		var pageBody = $('#page-wrap-inner');
		var secondMenu = mobileNavItems.find('ul.second');
		if( mobileBtn.length > 0 )
		{
			// Load Menu
			mobileBtn.click(function() {
				if(mobileBtn.hasClass('menu-open') == true) {
					pageBody.removeClass('push');
					mobileBtn.removeClass('menu-open');
				} else {
					pageBody.addClass('push');
					mobileBtn.addClass('menu-open');
				}
			});

			$('.triggerSubMenu').unbind().click(function(e) {
				var clicked = e.currentTarget.id.replace("showMenu","");
				var target = mobileNavItems.find('#subMenu' + clicked);

				secondMenu.slideUp(function() {
					secondMenu.removeClass('open');
				});

				target.slideDown(function() {
					target.addClass('open');
				});
			});
		}

		$('#mobile-nav-inner-items').hcSticky({
			bottom:0
		});

	},

	dateSelector: function() {
		if($('.datepicker').length > 0)
		{
			var d = new Date();
			var today = d.getDate() + " " + this.monthNames[d.getMonth()] + " " + d.getFullYear().toString().substr(2,2);
			$(".datepicker").minical({
				trigger: '.datepicker',
				from: new Date(),
				initialize_with_date: false
			}).val('Starting ' + today);
		}
	},

	inlineEnquiries: function()
	{
		var enquiry_btn = $('#enquiry_btn');
		var anchors = $('a');

		anchors.click(function(e) {
			var selected = $(this);
			var href = selected.attr('href');
			if(href == '#enquire') {
				var title = selected.attr('data-title');
				e.preventDefault();
				$.fancybox.open({
						fitToView	: false,
						autoSize	: true,
						type: 'ajax',
						padding: 0,
						href: 'frontend/view/inc/forms/enquiry.inc.php',
						beforeShow: function() {
							$('.fancybox-inner h3').html("Enquire About " + title);
	        		$("body").css({'overflow-y':'hidden'});
						},
						afterClose: function() {
	        		$("body").css({'overflow-y':'visible'});
						},
						afterShow: function() {
							var form = $('.fancybox-inner form');

							form.submit(function(e) {
								e.preventDefault();
								var loader = $('#loader');
								var data = {};
									data['name_txt'] = $('.fancybox-inner form #name_txt').val();
									data['email_txt'] = $('.fancybox-inner form #email_txt').val();
									data['telephone_txt'] = $('.fancybox-inner form #telephone_txt').val();
									data['company_txt'] = $('.fancybox-inner form #company_txt').val();
									data['location_txt'] = $('.fancybox-inner form #location_txt').val();
									data['message_txt'] = $('.fancybox-inner form #message_txt').val();
									data['pageName'] = title;
									data['list_txt'] = "";
									data['url'] = 'http://' + window.location.hostname + window.location.pathname;

								form.hide();
								loader.fadeIn();


								$.ajax({
									type: 'POST',
									url: './ajax/sendEnquiry.inc.php',
									data: data,
								}).done(function(response) {
									loader.hide();
									form.html(response).show();
								});

								return false;
							});
						}
					});
				}
		});
	},

	onResize: function()
	{

	},

	setGallery: function()
	{

		$('#slideshow-inner').on('init', function(slick) {
			$('#rightBtn').trigger('click');
			setTimeout(function(){
				$('.loader').css('opacity', 0).css('zIndex', 0);
			}, 2000);
		}).slick({
			centerMode: true,
			nextArrow: '<span id="rightBtn" class="rightBtn"><i class="fa fa-angle-right"></i></span>',
			prevArrow: '<span id="leftBtn" class="leftBtn"><i class="fa fa-angle-left"></i></span>',
			speed: 300,
		  centerMode: true,
		  slidesToShow: 1,
		  variableWidth: true
		})

	},

	banners: function() {
		var banners = $('#banners');

		if(banners.length > 0) {
			banners.slick({
				dots: true,
			  infinite: true,
			  speed: 500,
			  fade: true,
				nextArrow: '<span id="rightBannerBtn" class="rightBannerBtn"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',
				prevArrow: '<span id="leftBannerBtn" class="leftBannerBtn"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
			  cssEase: 'linear',
        appendDots: '.banner-paging .inner-wrap',
				slide: '.item'
			});
		}
	},

	products: function() {

	},

	setMinorTabs: function() {
		if($('#minor-tabs').length > 0) {
			var tabs = $('#minor-tabs');
			var btns = tabs.find('li');
			var anchors = tabs.find('a');
			var current = 0;

			btns.unbind().click(function(e) {
				var selected = $(this).attr('id').replace("btn_", "");
				if(current != selected) {
					// selected tab
					btns.removeClass('selected');
					$('#btn_' + selected).addClass('selected');
					// selected textarea
					$('#item_' + current).addClass('hidden');
					$('#item_' + selected).fadeIn().removeClass('hidden').css("display","inline-block");
					// updated current
					current = selected;
				}
			});

		}
	},

	validateEmail: function(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
	},

	setTabs: function() {
		if($('#tabs').length > 0) {
			var width = $(window).width();
			var tabs = $('#tabs');
			var btns = tabs.find('li');
			var anchors = tabs.find('a');
			var current = 0;

			if(width > 930) {
				anchors.unbind().click(function(e) {
					e.preventDefault();
				});
				$('#item_' + current).removeClass('hidden');

				btns.unbind().click(function(e) {
					var selected = $(this).attr('id').replace("btn_", "");
					if(current != selected) {
						// selected tab
						btns.removeClass('selected');
						$('#btn_' + selected).addClass('selected');
						// selected textarea
						$('#item_' + current).addClass('hidden');
						$('#item_' + selected).fadeIn().removeClass('hidden').css("display","inline-block");
						// updated current
						current = selected;
					}
				});
			} else {
				anchors.unbind();
				$('.txt-item-wrap').addClass('hidden').attr('css');
			}
		}

	},

	setGallery: function()
	{
		$('#slideshow-inner').on('init', function(slick) {
			$('#rightBtn-gallery').trigger('click');
			setTimeout(function(){
				$('.loader').css('opacity', 0).css('zIndex', 0);
			}, 1600);
		}).slick({
			centerMode: true,
			nextArrow: '<span id="rightBtn-gallery" class="rightBtn-gallery"><i class="fa fa-angle-right"></i></span>',
			prevArrow: '<span id="leftBtn-gallery" class="leftBtn-gallery"><i class="fa fa-angle-left"></i></span>',
			speed: 300,
		  centerMode: true,
		  slidesToShow: 1,
		  variableWidth: true
		})

	},

	jumpToLinks: function()
	{
		$('a[href^=#]').click(function(e){
    	var name = $(this).attr('href').substr(1);
    	var pos = $('#'+name).offset();
    	$('body').animate({ scrollTop: pos.top });
    	e.preventDefault();
  	});
	},

	productGallery: function() {
		if( $('#gallery-img-thumb').length > 0 ) {
			$('#productFeatureImg').on('cycle-next cycle-prev', function(e, opts) {
				$('#gallery-img-thumb').cycle('goto', opts.currSlide);
			});

			$('#gallery-img-thumb .thumb-outter').click(function(){
					var index = $('#gallery-img-thumb').data('cycle.API').getSlideIndex(this);
					$('#productFeatureImg').cycle('goto', index);
			});

		}
	},
	productSearch: function() {
		var cat = $('#cat_sel').val();
		var capacity = $('#capacity_sel').val();
		var type = $('#type_sel').val().toLowerCase();
		var loader = $('#loader');
		var url = false;
		var lookUp = "";

		$('ul.listProducts li').hide();

		var products = $('ul.listProducts li.show');
		products.removeClass('show');
		products = $('ul.listProducts li');

		url = pawLibrary.insertParam('cat_sel', cat, url);
		if(cat != 0)
		{
			lookUp += ".cat" + cat;
		}

		url = pawLibrary.insertParam('capacity_sel', capacity, url);
		if(capacity != '')
		{
			lookUp += ".cap" + capacity;
		}

		url = pawLibrary.insertParam('cat_sel', cat, url);
		if(type != '')
		{
			lookUp += "." + type;
		}

		if(((cat == 0) || (cat == '')) && (capacity == '') && (type == ''))
		{
			$('ul.listProducts li').addClass('show');
		} else {
			$(lookUp).addClass('show');
		}

		products = $('ul.listProducts li.show');
		products.fadeIn();

		pawLibrary.updateProductURL(url);
	},
	insertParam: function(key, value, loc)
	{
		var params = false;

		if( loc == false )
		{
		    var loc = location.href;
		    	loc += '?cat_sel=&capacity_sel=&type_sel=';
		}

	    if(loc.indexOf("?") !== -1) {
	       loc = loc.split('?');
	       params = loc[1];
	       loc = loc[0];
	    }
	    loc = loc + '?';

	    if(params != false)
	    {
		    params = params.split('&');
		    var x;
		    for(x =0; x < params.length; x++)
		    {
		    	var tmp = params[x].split('=');
		    	if(tmp[0]==key) {
		    		loc += key + '=' + value + '&';
		    	} else {
		    		loc += tmp[0] + '=' + tmp[1] + '&';
		    	}
		    }
		    loc = loc.slice(0,-1);
		} else {
			loc += key + '=' + value;
		}

		return loc;
	},

	updateProductURL: function(url)
	{
		window.history.pushState(null, null, url);
	},

	timeline: function()
	{
		$('#about-timeline-js').slick({
		  centerMode: true,
		  centerPadding: '60px',
		  slidesToShow: 3,
			nextArrow: '<span id="right-arrow-btn" class="right arrow"><i class="fa fa-angle-right"></i></span>',
			prevArrow: '<span id="left-arrow-btn" class="left arrow"><i class="fa fa-angle-left"></i></span>',
		  responsive: [
		    {
		      breakpoint: 900,
		      settings: {
		        arrows: true,
		        centerMode: true,
		        centerPadding: '40px',
		        slidesToShow: 2
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        arrows: false,
		        centerMode: true,
		        centerPadding: '40px',
		        slidesToShow: 1
		      }
		    }
		  ]
		});
	},



	exportImgGallery: function() {
	 var numSlick = 0;
	 $('.exportImgGallery').each( function() {
		 numSlick++;
		 $(this).addClass( 'slider-' + numSlick ).slick({
			 dots: false,
			 infinite: true,
			 speed: 500,
			 slidesToShow: 1,
			 slidesToShow: 1,
			 fade: true,
			 cssEase: "linear",
			 arrows: true,
			 autoplay: false,
			 nextArrow: '<span class="export-next"><i class="fa fa-angle-right"></i></span>',
			 prevArrow: '<span class="export-prev"><i class="fa fa-angle-left"></i></span>'

		 });



	 });

	},

	searchSuppliers: function() {
		var search = $('#location_txt');
		var location_btn = $('#location_btn');

		search.geocomplete().bind("geocode:result", function(event, result){
			//location_btn.trigger('click');
    })

		$('#tryAgain_btn').click(function() {
			$('#enter-location-results').hide();
			$('#enter-location-frm').fadeIn();
		});

		location_btn.click(function(e) {
			e.preventDefault();
			var address = search.val();
			var geocoder = new google.maps.Geocoder();
			var geo_lat = 0;
			var geo_lng = 0;

			geocoder.geocode( {
				'address': address
					}, function(results, status) {
						  if (status == google.maps.GeocoderStatus.OK)
						  {
								var p1 = results[0].geometry.location;
								var selected = 0;
								var current = 0;
								$.each(pawLibrary.markers, function(k,row) {
									var p2 = new google.maps.LatLng(row.lat, row.lng);
									var distance = pawLibrary.calculateDistance(k,p1,p2);
											distance = parseFloat(distance);
									pawLibrary.markers[k].distance = distance;
									if(k == 0) {
										current = parseFloat(distance);
										selected = k;
									} else {
										if(distance < current) {
											current = parseFloat(distance);
											selected = k;
										}
									}
								});

								$('#nearest-supplier-title').html(pawLibrary.markers[selected].title);
								$('#nearest-supplier-address').html(pawLibrary.markers[selected].address);
								$('#nearest-supplier-miles').html(pawLibrary.markers[selected].distance);

								$('#enter-location-frm').hide();
								$('#enter-location-results').fadeIn();


							}
			});

		});
	},

	calculateDistance: function(key, p1, p2)
	{
			var distance = (google.maps.geometry.spherical.computeDistanceBetween(p1, p2) / 1000).toFixed(2);
			var miles = distance * 0.6214;

			return parseFloat(Math.round(miles * 100) / 100).toFixed(2);

	},

	homenewscarousel: function()
	{
				var homenewscarousel = $('.homenewscarousel');
				if(homenewscarousel.length > 0) {

				homenewscarousel.slick({
					infinite: true,
					slidesToShow: 3,
					slidesToScroll: 3,
					autoplay: true,
					nextArrow: '.slick-next',
					prevArrow: '.slick-prev',
					responsive: [

					{
						breakpoint: 850,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2
						}
					},
						{
							breakpoint: 450,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1
							}
					}
					]
				});
			}
	},

	meetTheTeam: function() {
		var search_txt = $('#search_txt');
		var search_btn = $('#search_btn');
		var members = [];
		/*
		var supplier_members = $('#list-staff-members li');
		supplier_members.each(function(key,row) {
			var item = $(row);
			members.push({
				'id': item.attr('data-id'),
				'latitude': item.attr('data-latitude'),
				'longitude': item.attr('data-longitude'),
			});
		});
		*/

		var lat = 0;
		var lng = 0;


		search_txt.geocomplete().bind("geocode:result", function(event, result){
			//search_btn.trigger('click');

			var postcode = false;
			$(result.address_components).each(function(key,row) {
				if((row.types[0] == 'postal_code') || (row.types[0] == 'postal_code_prefix')) {
					postcode = row.long_name;
					pawLibrary.orderStaffMembers(postcode);
				}
			});
			if(postcode == false) {
				lat = result.geometry.location.lat();
				lng = result.geometry.location.lng();
        var latlng = {lat: parseFloat(lat), lng: parseFloat(lng)};
				var geocoder = new google.maps.Geocoder();
				geocoder.geocode({'location': latlng}, function(results, status) {
					if (status == google.maps.GeocoderStatus.OK) {
						$(results).each(function(key,row) {
							if(postcode == false) {
								var address = row.address_components;
								$(address).each(function(typeId,type) {
									if((type.types[0] == 'postal_code') || (type.types[0] == 'postal_code_prefix')) {
										postcode = type.long_name;
										pawLibrary.orderStaffMembers(postcode, address);
									}
								});
							}
						});
					}
				});

			}

		});


		search_btn.click(function(e) {
			e.preventDefault();
			if((lat == 0) || (lng == 0)) {
				var address = search_txt.val();
				var geocoder = new google.maps.Geocoder();
				geocoder.geocode( {
					'address': address
						}, function(results, status) {
							if (status == google.maps.GeocoderStatus.OK) {
								var postcode = false;
								$(result.address_components).each(function(key,row) {
									if((row.types[0] == 'postal_code') || (row.types[0] == 'postal_code_prefix')) {
										postcode = row.long_name;
										pawLibrary.orderStaffMembers(postcode, result.address_components);
									}
								});
								if(postcode == false) {
									lat = result.geometry.location.lat();
									lng = result.geometry.location.lng();
									var latlng = {lat: parseFloat(lat), lng: parseFloat(lng)};
									var geocoder = new google.maps.Geocoder();
									geocoder.geocode({'location': latlng}, function(results, status) {
										if (status == google.maps.GeocoderStatus.OK) {
											$(results).each(function(key,row) {
												if(postcode == false) {
													var address = row.address_components;
													$(address).each(function(typeId,type) {
														if((type.types[0] == 'postal_code') || (type.types[0] == 'postal_code_prefix')) {
															postcode = type.long_name;
															pawLibrary.orderStaffMembers(postcode, address);
														}
													});
												}
											});
										}
									});

								}
							}
						}
				);
			} else {
				var latlng = {lat: parseFloat(lat), lng: parseFloat(lng)};
				var geocoder = new google.maps.Geocoder();
				var postcode = false;
				geocoder.geocode({'location': latlng}, function(results, status) {
					if (status == google.maps.GeocoderStatus.OK) {
						$(results).each(function(key,row) {
							if(postcode == false) {
								var address = row.address_components;
								$(address).each(function(typeId,type) {
									if((type.types[0] == 'postal_code') || (type.types[0] == 'postal_code_prefix')) {
										postcode = type.long_name;
										pawLibrary.orderStaffMembers(postcode, address);
									}
								});
							}
						});
					}
				});
			}
		});
	},

	orderStaffMembers: function(postcode, address) {
		postcode_prefix = postcode.match('^[A-Z]{1,2}');
		var found = false;

		$("ul#list-staff-members li").removeClass('unselected').removeClass('selected'); // reset state

		if(postcode_prefix !== null) {
			var supplier_members = $('#list-staff-members li');
			supplier_members.each(function(key,row) {
				var item = $(row);
				item.removeClass('selected');
				var memberPostcodes = item.attr('data-postcodes');
				if(memberPostcodes.length > 0) {
						memberPostcodes = memberPostcodes.split(',');
						$(memberPostcodes).each(function(memberPostcodeId,memberPostcode) {
							var memberPostcode = $.trim(memberPostcode);
							if(memberPostcode == postcode_prefix[0]) {
								item.addClass('selected');
								found = true;
							}
						})
				}
			});
		}

		if(found == true) {
			$("ul#list-staff-members li:not(.selected)").addClass('unselected');
		} else {
			// IF NOT FOUND .. CHECK IF ADDRESS IS UNITED KINGDOM OR NOT
			var isUnitedKingdom = false;
			$.each(address, function(key,row) {
				if(row.short_name === 'GB') {
					isUnitedKingdom = true;
				}
			});

			if(isUnitedKingdom === false) {
				$("ul#list-staff-members li").addClass('unselected');
				$("ul#list-staff-members li:nth-child(1)").removeClass('unselected').addClass('selected');
			}


		}
	},

	sort_li: function(a, b){
    return ($(b).data('distance')) < ($(a).data('distance')) ? 1 : -1;
	},

	init: function()
	{
		pawLibrary.mobileNav();
		pawLibrary.inlineEnquiries();
		pawLibrary.banners();
		//pawLibrary.jumpToLinks();
		pawLibrary.productGallery();
		pawLibrary.dropdownMenus();
		pawLibrary.setGallery();
		pawLibrary.homenewscarousel();

		if($('#tab-btns').length > 0) {
			var tabs = $('#tab-btns li');
			var tabsContent = $('.tab-content-inner');
			tabs.click(function() {
				var selected = $(this).attr('id').replace("tab-btn-", "");
				tabs.removeClass('selected');
				$('#tab-btn-' + selected).addClass('selected');
				//
				tabsContent.hide();
				$('#tab-content-' + selected).fadeIn();
			});
		}

		if($('#spec-btns').length > 0) {
			var spec_tabs = $('#spec-btns li');
			var spec_content = $('.spec-items-inner');
			spec_tabs.click(function() {
				var selected = $(this).attr('id').replace("tab-btn-", "");
				spec_tabs.removeClass('selected');
				$('#tab-btn-' + selected).addClass('selected');
				//
				spec_content.hide();
				$('#spec-content-' + selected).fadeIn();
			});
		}

		var fancyBox = $('.fancybox');
		if(fancyBox.length > 0) {
			fancyBox.fancybox({
				padding: 0
			});
		}

		if( $('.filterItems').length > 0 )
		{
			$('.filterItems').on('change', function() {
				pawLibrary.productSearch();
			});
		}

		var base = $('base').attr('href');
		$('#mobile-sub-menu').on('change', function() {
			var value = $(this).val().replace("./", "");
			window.location = base + value;
		});

		if($('#about-timeline-js').length > 0) {
			pawLibrary.timeline();
		}

		if($('.exportImgGallery').length > 0) {
			pawLibrary.exportImgGallery();
		}

		if($('#location_txt').length > 0) {
			pawLibrary.searchSuppliers();
		}

		// MEET THE TEAM PAGE
		if($('#list-staff-members').length > 0 ) {
			pawLibrary.meetTheTeam();
		}

	}

}


$(window).load(function() {
	pawLibrary.init();
});
